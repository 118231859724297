<template>
  <div>
    <v-card tile  style="background:#eee">
      <v-toolbar dense dark color="primary" elevate-on-scroll>
        <v-btn icon dark @click="fechaModal">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="carregouCategoria">
          {{$tc('label.categoria', 2)}} e {{$tc('label.fornecedor', 2)}}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pt-3">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-container>
            <v-row align="center" justify="space-around">
              <v-col cols="12" sm="8" align-self="center">
                <div class="PlanejamentoAcaoFormApuracao">
                  <div class="mt-3 mb-2 title-float accent--text">
                    {{$tc('label.categoria', 1)}}
                  </div>
                  <v-card>
                    <v-container fluid grid-list-md>
                      <v-autocomplete
                        id="combo-categorias-fornecedor"
                        v-model="categoria.idsCategoriasAgrupadas"
                        :items="listaCategoriasCopia"
                        chips
                        deletable-chips
                        multiple
                        clearable
                        :rules="[rules.requiredLista]"
                        autocomplete="off"
                        item-disabled="atingiuMaximo"
                        item-text="codNome"
                        item-value="id"
                        :label="`${$tc('label.categoria', 1)} *`"
                        :disabled="somenteLeitura || !permiteAlterarCategoria"
                        >
                        <template slot="item" slot-scope="{ item }">
                          <v-list-item-icon>
                            <v-icon v-if="categoria.idsCategoriasAgrupadas.includes(item.id) || !item.atingiuMaximo" v-bind:class="{ 'primary--text': item.atingiuMaximo}">
                              {{ categoria.idsCategoriasAgrupadas.includes(item.id) ? 'check_box' : 'check_box_outline_blank' }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content v-bind:class="{ 'primary--text': item.atingiuMaximo}">
                            <span>{{ item.codNome }}</span>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon color="primary"
                              v-if="item.atingiuMaximo">
                              playlist_add_check
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-autocomplete>
                    </v-container>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="PlanejamentoAcaoFormApuracao">
                  <div class="mt-3 mb-2 title-float accent--text">
                    {{$tc('label.fornecedor', 1)}}
                  </div>
                  <v-card>
                    <v-container fluid grid-list-md>
                      <v-autocomplete
                        id="planejamento-autocomplete-fornecedor"
                        v-model="categoria.fornecedores"
                        clearable
                        chips
                        multiple
                        deletable-chips
                        :filter="filtrarFornecedores"
                        :rules="[rules.requiredLista]"
                        :items="listaFornecedores"
                        :label="`${$tc('label.fornecedor', 1)} *`"
                        :no-data-text="$tc('message.nenhum_registro', 1)"
                        item-text="cnpjNomeCod"
                        item-value="id"
                        required
                        :disabled="true"
                        :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
                        @click.native="buscaFornecedores">
                      </v-autocomplete>
                    </v-container>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions  style="height: 1200px; align-items: start;">
        <v-container>
            <v-row>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn @click="fechaModal">{{ somenteLeitura ? $t('label.fechar') : $t('label.cancelar') }}</v-btn>
                  <v-btn color="primary" class="ml-3" @click="confirma" v-if="!somenteLeitura">{{ $t('label.ok') }}</v-btn>
                </v-row>
              </v-col>
            </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { copyObject } from '../../../../common/functions/helpers';

export default {
  props: {
    somenteLeitura: Boolean,
    objetoPassoGeralContrato: {
      type: Object,
      required: true,
    },
    listaCategorias: {
      type: Array,
      default: [],
    },
  },
  components: {
  },
  watch: {
  },
  computed: {
    carregouCategoria() {
      return this.categoria != null;
    },
  },
  data() {
    return {
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),

      categoria: {
        fornecedores: [],
      },

      listaFornecedores: [],
      listaCategoriasCopia: [],

      permiteAlterarCategoria: false,

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
      },

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm6: true,
        md3: true,
      },
    };
  },
  methods: {
    filtrarFornecedores(item, queryText) {
      return item.cnpjNomeCod.toLowerCase().indexOf(queryText.toLowerCase()) > -1
        || item.desCnpj.replace(/[./-]/g, '').indexOf(queryText) > -1;
    },
    buscaFornecedores() {
      const parametros = {
        pagador: false,
      };
      if (this.objetoPassoGeralContrato.grupoFornecedor && this.objetoPassoGeralContrato.grupoFornecedor.id) {
        parametros.idGrupoFornecedor = this.objetoPassoGeralContrato.grupoFornecedor.id;
      } else if (this.objetoPassoGeralContrato.fornecedor && this.objetoPassoGeralContrato.fornecedor.id) {
        parametros.idFornecedor = this.objetoPassoGeralContrato.fornecedor.id;
      }
      this.planejamentoContratoResource.buscarFornecedores(parametros)
        .then((res) => {
          this.listaFornecedores = res.data;
          if (this.listaFornecedores.length) {
            this.categoria.fornecedores = [];
            this.listaFornecedores.filter((el) => this.categoria.fornecedores.push(el.id));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    setarCategoria(categoria) {
      this.listaCategoriasCopia = copyObject(this.listaCategorias);
      this.categoria = categoria;
      if (this.categoria != null) {
        if (!this.categoria.fornecedores) {
          this.categoria.fornecedores = [];
        }
        this.categoria.idsCategoriasAgrupadas.forEach((id) => {
          const cat = this.listaCategoriasCopia.find((c) => c.id === id);
          cat.atingiuMaximo = false;
        });
        this.buscaFornecedores();
      }
    },
    setarPermissaoAlterarCategorias(permiteAlterarCategoria) {
      this.permiteAlterarCategoria = permiteAlterarCategoria;
    },
    resetCategoria() {
      this.categoria.fornecedores = [];
    },
    confirma() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit('PlanejamentoContratoModalCategoriaFornecedor__CadastrarModalCategoriaFornecedor', this.categoria);
    },
    fechaModal() {
      this.$emit('PlanejamentoContratoModalCategoriaFornecedor__FecharModalCategoriaFornecedor', this.categoria);
      this.$refs.form.resetValidation();
      this.listaFornecedores = [];
      if (!this.categoria.acoes) {
        this.resetCategoria();
      }
    },
  },
  mounted() {
  },
};
</script>
<style>
.PlanejamentoAcaoFormApuracao .v-text-field:not(.error--text) .v-text-field__details {
  display: none;
}
.PlanejamentoAcaoFormApuracao .title-float {
  font-weight: 400;
  font-size: 20px;
}
</style>
